<template>
	<div
		data-component="lesson-nav"
		:data-visible="getIsVisible"
	>
		<nav-button
			icon="cross"
		/>
		<h1 data-element="course-title">
			<router-link
				:to="getCourseRoute"
			>
				{{ getCourseTitle }}
			</router-link>
		</h1>
		<p data-element="lesson-title">
			<router-link
				:to="getLessonRoute"
			>
				{{ getLessonTitle }}
			</router-link>
		</p>

		<h2 data-element="activities-heading">
			Activities in this lesson
		</h2>

		<div class="activity__block--groups">
			<div
				class="group-item"
				v-for="group in activityList"
				:key="group.activityGroupNumb"
			>
				<div class="group-item__head">
					<div class="group-item__head--title">
						<icon
							:icon="
								group.type === 'quiz' ? 'v2-quiz-mini' : 'v2-video-mini'
							"
							colour="transparent"
						/>
						{{ group.title }}
					</div>

				</div>
				<div class="group-item__body">
					<V2Button
						v-for="activity in group.item"
						:key="activity.id"
						:class="{ active: activity.id === currentActivity.id }"
						@click="openActivity(activity.id)"
						class="group-item__body-item"
						variant="text"
					>
						<template v-if="activity.type !== 'quiz'">
							{{ `${group.activityGroupNumb}.0 ${activity.title}` }}
						</template>

						<template v-else>
							{{
								`${group.activityGroupNumb}.${activity.activityGroupItemNumb} Question`
							}}
							<span class="bold">
								#{{ activity.activityGroupItemNumb }}?
							</span>
						</template>
					</V2Button>
				</div>

			</div>
		</div>

	</div>
</template>

<script>

	import NavButton    from '@/components/lesson/lessonNav/NavButton';
	import routeParams  from '@/mixins/routeParams';
	import Icon from '@/components/ui/Icon';
	import V2Button from '@/components/v2/ui/V2Button';

	export default {
		components: {
			NavButton,
			Icon,
			V2Button
		},
		mixins: [routeParams],
		data: () => ({}),
		computed: {
			getIsVisible () {
				return this.$store.getters['lesson/getLessonNavIsVisible'];
			},
			getCourseTitle () {
				return this.$store.getters['lesson/getCourseTitle'];
			},
			getLessonTitle () {
				return this.$store.getters['lesson/getLessonTitle'];
			},

			lesson () {
				return this.$store.state.lesson.v2Lesson;
			},

			activities () {
        if (!this.lesson) {
          return [];
        }
        return this.lesson.activities;
			},

			activityList () {
				return this.activities.reduce((prev, curr) => {
					const prevIdx = prev.length;

          if (curr.type === 'quiz') {
            if (prevIdx === 0 || prev[prevIdx - 1].type !== 'quiz') {
              prev.push({
                title: 'Quiz',
                type: 'quiz',
                activityGroupNumb: prev.length + 1,
                item: [{ ...curr, activityGroupItemNumb: 1 }]
              });
            } else {
              prev[prevIdx - 1].item.push({
                ...curr,
                activityGroupItemNumb: prev[prevIdx - 1].item.length + 1
              });
            }
          }

          if (curr.type === 'video') {
            prev.push({
              title: 'Video',
              type: 'video',
              activityGroupNumb: prev.length + 1,
              item: [curr]
            });
          }

          if (curr.type === 'embeddedPresentation') {
            prev.push({
              title: 'Presentation',
              type: 'embeddedPresentation',
              activityGroupNumb: prev.length + 1,
              item: [curr]
            });
          }

          if (curr.type === 'titlePage') {
            prev.push({
              title: 'Intertitle',
              type: 'titlePage',
              activityGroupNumb: prev.length + 1,
              item: [curr]
            });
          }

					return prev;
				}, []);
			},

			currentGroup () {
				const mappedItemIdIdx = this.activityList
					.map((group) => {
						return group.item.map(({ id }) => id);
					})
					.findIndex((idGroup) => idGroup.includes(this.getActivityId));

        if (mappedItemIdIdx === -1) {
          return null;
        }

				return this.activityList[mappedItemIdIdx];
			},

			currentActivity () {
        if (!this.currentGroup) {
          return null;
        }
        return this.currentGroup.item.find(({ id }) => id === this.getActivityId);
			}
		},
		watch: {},
		created () {
			this.setLesson();
		},
		methods: {
			toggleIsVisible () {
				this.$store.commit('lesson/toggleLessonNavIsVisible');
			},
			openActivity (id) {
				this.$router.replace({ params: { activityId: id } });
			},
			setLesson () {}
		}
	};

</script>

<style lang="scss" scoped>

[data-element='activities-heading'] {
  margin:rem(28) 0 rem(14);
  @include font(18px, $c-dark-navy, 700, 22px)
}

.activity__block--groups {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;

  .group-item {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid $c-light-navy;

    &__head {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      @include font(18px, $c-dark-navy, 400, 23px);

      &--title {
        display: flex;
        gap: 8px;
      }

      .controll-icon {
        cursor: pointer;
      }

      .inactive {
        transform: rotate(0deg);
      }
    }

    &__body {
      display: flex;
      flex-direction: column;

      &-item {
        width: 100%;
        padding: 6px 8px;
        border-radius: 2px;
        @include font(16px, $c-dark-navy, 600, 20px);
        border: 1px solid transparent;
        background: transparent;
        justify-content: flex-start;

        span {
          margin-left: 4px;
        }

        &.active {
          background: $c-medium-navy;
          color: $c-white-l;
          font-weight: 700;

          &:hover {
            background: $c-medium-navy;
            border-color: $c-medium-navy;
          }
        }
      }
    }
  }
}

	[data-component='lesson-nav'] {
		overflow-y:auto;
		position:fixed;
		z-index:7;
		overflow-y:auto;
		top:0;
		left:100vw;
		width:320px;
		height:100vh;
		height:-webkit-fill-available;
		padding:rem(16);
		background-color:$c-white;
		transition:transform 0.6s ease;
		box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.72);
		&[data-visible] {
			transform:translate3d(-100%,0,0);
		}
		[data-component='nav-button'] {
			position:absolute;
			top:rem(12);
			right:rem(12);
		}
		[data-element='course-title'] {
			margin:rem(32) 0 0;
			a {
        @include font(18px, $c-dark-navy, 700, 2px);
				&:hover {
					text-decoration:underline;
				}
			}
		}
		[data-element='lesson-title'] {
			margin:rem(2) 0 0;
			a {
        @include font(27px, $c-dark-navy, 700, 30px);

				&:hover {
					text-decoration:underline;
				}
			}
		}
	}

</style>
